import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { Button, CircularProgress } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const UploadFileButton = ({ allowedFormats, maxSizeInMB = 10, additionalMessage, onUploadComplete, children }) => {
  const { t } = useTranslation()
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && !isUploading) {
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (allowedFormats && !allowedFormats.includes(file.type)) {
        alert(`${t('file_upload_error_type')}${allowedFormats.join(', ')}.`);
        return;
      }

      if (file.size > maxSizeInBytes) {
        alert(`${t('file_upload_error_size')}${maxSizeInMB}MB.`);
        return;
      }

      setSelectedFile(file);
      uploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    setIsUploading(true);

    if (onUploadComplete) {
        await onUploadComplete(file);
    }

    setIsUploading(false);
  };

  return (
    <>
        <div className="upload-button">
            <input
                accept={allowedFormats?.map(format => `.${format.split('/')[1]}`).join(',')}
                style={{ display: 'none' }}
                id="upload-file-button"
                type="file"
                onChange={handleFileChange}
            />
            <label htmlFor="upload-file-button">
                <Button
                variant="contained"
                component="span"
                startIcon={isUploading ? <CircularProgress size={20} /> : <UploadFileIcon />}
                disabled={isUploading}
                >
                {isUploading ? t('file_upload_loading') : children}
                </Button>
            </label>
        </div>
        <div>
            <ul style={{margin: '10px 12px 0', fontSize: '11px'}}>
                {allowedFormats && (
                    <li>{t('file_upload_info_type')}<b>{allowedFormats.map(format => `.${format.split('/')[1]}`).join(', ')}</b></li>
                )}
                <li>{t('file_upload_info_size')}<b>{maxSizeInMB}MB</b></li>
                {additionalMessage && (
                    <li><b>{additionalMessage}</b></li>
                )}
            </ul>
        </div>
    </>
  );
};

export default UploadFileButton;
