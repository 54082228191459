import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode'
import settings from 'settings'

const initialState = {
    id: null,
    mid: null,
    name: null,
    type: null,
    randomization: [],
    sendbutton: false,
    api: 0,
    key: null,
    payload: null,
    logo: settings.logo,
    title: settings.title,
    support: "",
    accesses: {},
    terms: {},
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser(state) {
            try {
                const user = jwtDecode(Cookies.get('auth', { domain: '.' + settings.base }));

                state.id = user.uid
                state.mid = user.mid
                state.name = user.mname
                state.type = user.type
                state.logo = user.logo
                state.title = user.title
                state.offset = user.offset
                state.support = user.support
                state.accesses = user.accesses
                state.terms = user.terms
                state.randomization = user.randomization
                state.sendbutton = user.sendbutton
                state.api = user.api
                state.key = user.key
                state.payload = user.payload
            } catch (e) {
                Cookies.remove('auth', { domain: '.' + settings.base });

                state.id = initialState.id
                state.mid = initialState.mid
                state.name = initialState.name
                state.type = initialState.type
                state.logo = initialState.logo
                state.title = initialState.title
                state.offset = initialState.offset
                state.support = initialState.support
                state.accesses = initialState.accesses
                state.terms = initialState.terms
                state.randomization = initialState.randomization
                state.sendbutton = initialState.sendbutton
                state.key = initialState.key
                state.payload = initialState.payload
            }
        },
        resetAuth(state) {
            Cookies.remove('auth', { domain: '.' + settings.base });

            state.id = initialState.id
            state.mid = initialState.mid
            state.name = initialState.name
            state.type = initialState.type
            state.logo = initialState.logo
            state.title = initialState.title
            state.offset = initialState.offset
            state.support = initialState.support
            state.accesses = initialState.accesses
            state.terms = initialState.terms
            state.randomization = initialState.randomization
            state.sendbutton = initialState.sendbutton
            state.key = initialState.key
            state.payload = initialState.payload
        },
        resetPayload(state) {
            state.id = initialState.id
            state.mid = initialState.mid
            state.name = initialState.name
            state.type = initialState.type
            state.logo = initialState.logo
            state.title = initialState.title
            state.offset = initialState.offset
            state.support = initialState.support
            state.accesses = initialState.accesses
            state.terms = initialState.terms
            state.randomization = initialState.randomization
            state.sendbutton = initialState.sendbutton
            state.key = initialState.key
            state.payload = initialState.payload
        },
    },
})

export const { setUser, resetAuth } = authSlice.actions
export default authSlice.reducer