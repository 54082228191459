import React from 'react'
import { useDispatch } from 'react-redux'
import { MenuItem, Menu, IconButton } from '@mui/material'
import LanguageIcon from '@mui/icons-material/Language'

import { setLanguage } from '../slices/AppSlice'

const LanguageSwitcher = () => {
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleChangeLanguage = (language) => {
        dispatch(setLanguage(language))
        handleClose()
    }

    return (
        <>
            <IconButton
                edge="end"
                color="inherit"
                aria-label="language-switcher"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <LanguageIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => handleChangeLanguage('en')}>English</MenuItem>
                <MenuItem onClick={() => handleChangeLanguage('ru')}>Русский</MenuItem>
                <MenuItem onClick={() => handleChangeLanguage('uz')}>O'zbek</MenuItem>
            </Menu>
        </>
    )
}

export default LanguageSwitcher