import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id: null,
    bank: null,
    ip: null,
    method: null,
    type: null,
    data: null,
    image: null,
    card: null,
    account: null,
    name: null,
    recipient: null,
    owner: null,
    phone: null,
    qrcode: null,
    date: null,
    status: null,
    duplicate: false,
    tds: false,
    redirect: false,
    approved: false,
    isSberpayConfirmed: false,
    isTpayConfirmed: false,
    isAppealEligible: false,
    isAppealPending: false,
}

const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        setTransaction(state, action) {
            state.id = action.payload.transaction
            state.bank = action.payload.bank
            state.ip = action.payload.ip
            state.method = action.payload.method
            state.type = action.payload.type
            state.data = action.payload.data
            state.image = action.payload.image
            state.card = action.payload.card
            state.account = action.payload.account
            state.name = action.payload.name
            state.recipient = action.payload.recipient
            state.owner = action.payload.owner
            state.phone = action.payload.phone
            state.qrcode = action.payload.qrcode
            state.date = action.payload.date
            state.status = action.payload.status
            state.duplicate = action.payload.duplicate
        },
        setTDS(state, action) {
            state.tds = action.payload
        },
        setRedirect(state, action) {
            state.redirect = action.payload
        },
        setStatus(state, action) {
            state.status = action.payload
            if (state.status === 'Rejected') {
                if (state.isAppealPending) {
                    state.isAppealEligible = false
                    state.isAppealPending = false
                } else if (state.approved) {
                    state.isAppealEligible = true
                }
            } else if (state.status === 'Appeal Pending') {
                state.isAppealEligible = true
                state.isAppealPending = true
            }
        },
        setApproved(state, action) {
            state.approved = action.payload
        },
        setSberpayConfirmed(state, action) {
            state.isSberpayConfirmed = action.payload
        },
        setTpayConfirmed(state, action) {
            state.isTpayConfirmed = action.payload
        },
        setAppealEligible(state, action) {
            state.isAppealEligible = action.payload
        },
        setAppealPending(state, action) {
            state.isAppealPending = action.payload
        },
        resetTransaction(state) {
            state.id = initialState.id
            state.bank = initialState.bank
            state.ip = initialState.ip
            state.method = initialState.method
            state.type = initialState.type
            state.data = initialState.data
            state.image = initialState.image
            state.card = initialState.card
            state.account = initialState.account
            state.name = initialState.name
            state.recipient = initialState.recipient
            state.owner = initialState.owner
            state.phone = initialState.phone
            state.qrcode = initialState.qrcode
            state.date = initialState.date
            state.status = initialState.status
            state.duplicate = initialState.duplicate
            state.tds = initialState.tds
            state.redirect = initialState.redirect
            state.approved = initialState.approved
            state.isSberpayConfirmed = initialState.isSberpayConfirmed
            state.isTpayConfirmed = initialState.isTpayConfirmed
            state.isAppealEligible = initialState.isAppealEligible
            state.isAppealPending = initialState.isAppealPending
        },
    },
})

export const {
    setTransaction,
    setStatus,
    setTDS,
    setRedirect,
    setApproved,
    setSberpayConfirmed,
    setTpayConfirmed,
    setAppealEligible,
    setAppealPending,
    resetTransaction
} = transactionSlice.actions
export default transactionSlice.reducer
