import React, { useState, useEffect, useRef } from 'react'

function TimerButton({ children, seconds }) {
    const [secondsLeft, setSecondsLeft] = useState(seconds)
    const [isDisabled, setIsDisabled] = useState(true)
    const hasMounted = useRef(false)

    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true
            setIsDisabled(true)
            setSecondsLeft(seconds)

            const timerInterval = setInterval(() => {
                setSecondsLeft(prev => {
                    if (prev <= 1) {
                        clearInterval(timerInterval)
                        setIsDisabled(false)
                        return 0
                    }
                    return prev - 1
                })
            }, 1000)

            return () => clearInterval(timerInterval)
        }
    }, [])

    return (
        <div>
            {React.cloneElement(children, {
                disabled: isDisabled,
                children: isDisabled
                    ? `${children.props.children} (${secondsLeft})`
                    : children.props.children,
            })}
        </div>
    )
}

export default TimerButton
