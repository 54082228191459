import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import settings from 'settings'

export const transifyApi = createApi({
    reducerPath: 'transifyApi',
    baseQuery: fetchBaseQuery({
        baseUrl: settings.api,
        credentials: 'include',
    }),
    endpoints: (builder) => ({
        authUser: builder.mutation({
            query: (data) => ({
                url: 'authUser.php',
                method: 'POST',
                body: JSON.stringify(data),
            }),
        }),
        getBankList: builder.mutation({
            query: (data) => ({
                url: 'prtGetBankList.php',
                method: 'POST',
                body: JSON.stringify(data),
            }),
        }),
        createTransaction: builder.mutation({
            query: (data) => ({
                url: 'prtCreateTransaction.php',
                method: 'POST',
                body: JSON.stringify(data),
            }),
        }),
        getTransactionStatus: builder.mutation({
            query: (data) => ({
                url: 'prtGetTransactionStatus.php',
                method: 'POST',
                body: JSON.stringify(data),
            }),
        }),
        initiatePNYX: builder.mutation({
            query: (data) => ({
                url: 'prtGetPNYXData.php',
                method: 'POST',
                body: JSON.stringify(data),
            }),
        }),
        cancelTransaction: builder.mutation({
            query: (data) => ({
                url: 'prtUserCancelTransaction.php',
                method: 'POST',
                body: JSON.stringify(data),
            }),
        }),
        processAppealData: builder.mutation({
            query: (data) => {
                const formData = new FormData();
                formData.append('confirmation', data.file);
                formData.append('transactionId', data.transactionId);

                return {
                    url: 'prtProcessAppealRequest.php',
                    method: 'POST',
                    body: formData,
                }
            },
        }),
    }),
})

export const {
    useAuthUserMutation,
    useGetBankListMutation,
    useCreateTransactionMutation,
    useGetTransactionStatusMutation,
    useInitiatePNYXMutation,
    useCancelTransactionMutation,
    useProcessAppealDataMutation,
} = transifyApi
