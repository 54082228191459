import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { IconButton, Typography, Tooltip, Stack, Snackbar, Alert, ButtonBase } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyLabel = ({ children }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(children)
      .then(() => {
        setOpen(true);
      })
      .catch((err) => {
        console.error('Failed to copy:', err);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonBase className="copy-label" onClick={handleCopy} sx={{ width: '100%' }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body1" fontWeight="bold">
            {children}
          </Typography>
          <Tooltip title="Copy to clipboard">
            <IconButton onClick={handleCopy} size="small">
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </ButtonBase>
      <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {t('text_copied_tooltip')}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CopyLabel;
