import { createSlice } from '@reduxjs/toolkit'
import settings from 'settings'

const initialState = {
    page: 0,
    banks: [],
    language: settings.defaultLanguage,
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setPage(state, action) {
            state.page = action.payload
        },
        setLanguage(state, action) {
            state.language = action.payload
        },
        setBanks(state, action) {
            state.banks = action.payload
        },
        resetApp(state) {
            state.page = initialState.page
            state.language = initialState.language
            state.banks = initialState.banks
        },
    },
})

export const { setPage, setLanguage, setBanks, resetApp } = appSlice.actions
export default appSlice.reducer