import React from 'react'
import { CurrencyExchangeRounded, AccountBalanceRounded, CreditScoreRounded } from '@mui/icons-material'
import { IconStepper } from './StyledComponents'

export default function StepIcons(props) {
    const { active, completed, className } = props

    const icons = {
        1: <CurrencyExchangeRounded />,
        2: <AccountBalanceRounded />,
        3: <CreditScoreRounded />,
    }

    return (
        <IconStepper ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </IconStepper>
    )
}