import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useGetBankListMutation } from '../app/API'

import { setPrice, setCurrency, setEmail, setError } from '../slices/DataSlice'
import { setPage, setBanks } from '../slices/AppSlice'

import { Grid, Select, MenuItem, Box, Button, TextField, Alert } from '@mui/material'

export default function MoneyForm() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [isNextDisabled, setIsNextDisabled] = React.useState(false)

  const {
    error, referer, price, currency, email, terms
  } = useSelector(state => ({
    error: state.root.data.error,
    referer: state.root.data.referer,
    price: state.root.data.price,
    currency: state.root.data.currency,
    email: state.root.data.email,
    terms: JSON.parse(state.root.auth.terms)
  }));

  const [getBankList] = useGetBankListMutation()

  const handlePriceChange = (event) => {
    const value = event.target.value;
    if (/^(0|[1-9]\d*)?(\.\d{0,2})?$/.test(value)) {
      dispatch(setPrice(value));
    }
  };

  const logout = async () => {
    window.location.href = referer
  };

  const submit = async () => {
    setIsNextDisabled(true);

    if (!currency || !price) {
      alert(t('error_no_price_selected'))
      setIsNextDisabled(false);
      return
    }

    const data = { currency, price, }

    try {
      const response = await getBankList(data).unwrap()
      dispatch(setBanks(response))

      dispatch(setError(false))
      dispatch(setPage(1))
    } catch (e) {
      dispatch(setError(true))
      setIsNextDisabled(false);
    }
  }

  React.useEffect(() => {
    if (terms && !currency) {
      const initialCurrency = Object.keys(terms)[0]
      dispatch(setCurrency(initialCurrency))
    }
  }, [terms, currency, dispatch]);

  return (
    <React.Fragment>
      {!referer && <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <TextField required inputProps={{ autoComplete: "off", pattern: "^(0|[1-9]\\d*)?(\\.\\d{0,2})?$" }} InputLabelProps={{ shrink: true }} id="price" name="price" label={t('amount')} value={price} onChange={handlePriceChange} fullWidth variant="standard" sx={{ flex: 1 }} />

            <Select id="currency" value={currency} onChange={(event) => dispatch(setCurrency(event.target.value))} sx={{ width: 100, ml: 2 }} >
              {Object.keys(terms).map((key) => (<MenuItem key={key} value={key}>{key}</MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField autoComplete='off' id="email" name="email" inputProps={{ autoComplete: "off" }} InputLabelProps={{ shrink: true }} label={t('email')} fullWidth variant="standard" value={email} onChange={(event) => dispatch(setEmail(event.target.value))} />
        </Grid>
      </Grid>}

      {referer && error && <Alert severity="warning" sx={{ mb: 4 }}>
        {t('error_create_transaction_404')}
      </Alert>}

      {!referer && <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto' }}>
        <Button color="error" variant="contained" onClick={submit} disabled={isNextDisabled} sx={{ mt: 3, ml: 1, minWidth: '100px', }}>
          {t('continue')}
        </Button>
      </Box>}

      {referer && error && <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto' }}>
        <Button variant="outlined" color="inherit" onClick={logout} fullWidth sx={{ mt: 2, }}>
          {t('back_to_store')}
        </Button>
      </Box>}
    </React.Fragment >
  )
}
