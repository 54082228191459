import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import { transifyApi } from './API'

import appReducer from '../slices/AppSlice'
import authReducer from '../slices/AuthSlice'
import dataReducer from '../slices/DataSlice'
import transactionReducer from '../slices/TransactionSlice'

import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  data: dataReducer,
  transaction: transactionReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: {
    root: persistedReducer,
    [transifyApi.reducerPath]: transifyApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }).concat(transifyApi.middleware),
})

export const persistor = persistStore(store)