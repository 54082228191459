import React from 'react'
import settings from 'settings'

export const Timer = ({ datetime }) => {
    const [timeRemaining, setTimeRemaining] = React.useState('00:00')

    React.useEffect(() => {
        const targetDatetime = new Date(datetime + 'Z').getTime() + settings.transactionDuration * 1000

        const intervalId = setInterval(() => {
            const now = new Date()
            const nowUTC = now.getTime() + (now.getTimezoneOffset() * 60000)
            const distance = targetDatetime - nowUTC
            if (distance < 0) {
                clearInterval(intervalId)
                setTimeRemaining('00:00')
            } else {
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
                const seconds = Math.floor((distance % (1000 * 60)) / 1000)

                if (minutes * 60 + seconds > settings.transactionDuration) {
                    clearInterval(intervalId)
                    setTimeRemaining('00:00')
                } else
                    setTimeRemaining(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`)
            }
        }, 1000)

        return () => clearInterval(intervalId)
    }, [datetime])

    return (
        <div>
            {timeRemaining}
        </div>
    )
}
