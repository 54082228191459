import React, { useState, useEffect, useRef } from 'react'

function TimerWrapper({ children, seconds, callback }) {
    const [secondsLeft, setSecondsLeft] = useState(seconds)
    const hasMounted = useRef(false)

    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true
            setSecondsLeft(seconds)

            const timerInterval = setInterval(() => {
                setSecondsLeft(prev => {
                    if (prev <= 1) {
                        clearInterval(timerInterval)
                        callback()
                        return 0
                    }
                    return prev - 1
                })
            }, 1000)

            return () => clearInterval(timerInterval)
        }
    }, [])

    return (
        <div>
            {React.cloneElement(children, {
                children: `${children.props.children} (${secondsLeft})`,
            })}
        </div>
    )
}

export default TimerWrapper
