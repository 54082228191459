import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    order: null,
    token: null,
    bank: null,
    type: null,
    price: null,
    email: null,
    name: null,
    surname: null,
    national: null,
    description: null,
    referer: null,
    callback: null,
    currency: null,
    error: false
}

const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setOrder(state, action) {
            state.order = action.payload
        },
        setToken(state, action) {
            state.token = action.payload
        },
        setCurrency(state, action) {
            state.currency = action.payload
        },
        setPrice(state, action) {
            state.price = action.payload
        },
        setEmail(state, action) {
            state.email = action.payload
        },
        setName(state, action) {
            state.name = action.payload
        },
        setSurname(state, action) {
            state.surname = action.payload
        },
        setNational(state, action) {
            state.national = action.payload
        },
        setDescription(state, action) {
            state.description = action.payload
        },
        setBank(state, action) {
            state.bank = action.payload.id
            state.type = action.payload.type
        },
        setRefererURL(state, action) {
            state.referer = action.payload
        },
        setCallbackURL(state, action) {
            state.callback = action.payload
        },
        setError(state, action) {
            state.error = action.payload
        },
        resetData(state) {
            state.order = initialState.order
            state.token = initialState.token
            state.bank = initialState.bank
            state.type = initialState.type
            state.price = initialState.price
            state.email = initialState.email
            state.name = initialState.name
            state.surname = initialState.surname
            state.national = initialState.national
            state.description = initialState.description
            state.currency = initialState.currency
            state.referer = initialState.referer
            state.callback = initialState.callback
            state.error = initialState.error
        },
    },
})

export const { setOrder, setToken, setCurrency, setPrice, setEmail, setName, setSurname, setNational, setDescription, setBank, setRefererURL, setCallbackURL, setError, resetData } = dataSlice.actions
export default dataSlice.reducer